import img1 from './1.webp';
import img2 from './2.webp';
import img3 from './3.webp';
import img4 from './4.webp';
import img5 from './5.webp';
import img6 from './6.webp';
import img7 from './7.webp';
import img8 from './8.webp';
import img9 from './9.webp';

export const projectImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
];
